import React from "react"
import clsx from "clsx"
import Image from "next/image"

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: number
  src: string
}

export const Avatar: React.FC<AvatarProps> = ({ size = 30, src, ...props }) => {
  return (
    <div className={clsx(props.className, "rounded-full")}>
      <Image
        alt="avatar"
        src={src.length !== 0 ? src : `https://singlecolorimage.com/get/979797/${size}x${size}`}
        className="rounded-full"
        placeholder="blur"
        blurDataURL={`https://singlecolorimage.com/get/979797/${size}x${size}`}
        width={size}
        height={size}
      />
    </div>
  )
}

interface ImageCoverProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size: [width: number, height: number]
  src: string
  rounded?: string
  alt: string
}

export const ImageCover: React.FC<ImageCoverProps> = ({
  size,
  src,
  rounded = "rounded",
  alt,
  ...props
}) => {
  return (
    <div className={clsx(rounded, props.className)}>
      <Image
        src={
          src.length !== 0 ? src : `https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}`
        }
        width={size[0]}
        height={size[1]}
        className={rounded}
        placeholder="blur"
        blurDataURL={`"https://singlecolorimage.com/get/979797/${size[0]}x${size[1]}"`}
        alt={alt}
      />
    </div>
  )
}
