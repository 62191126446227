import clsx from "clsx"

import { FlexBox } from "@components/wrapper"
import { Helmet } from "@helpers/Helmet"
import Image from "next/image"

const Error = () => {
  return (
    <>
      <Helmet title="Server Error" />
      <FlexBox
        direction="col"
        className={clsx("w-4/5 h-screen mx-auto")}
        justify="center"
        align="center"
      >
        <div className="flex justify-center w-full lg:flex-row flex-col">
          <div className="mx-auto">
            <Image
              src="https://harisenin-storage.s3.ap-southeast-1.amazonaws.com/public/assets/pages/error-page_asset.png"
              alt="image page error"
              width={568}
              height={400}
            />
          </div>

          <FlexBox className="mx-auto text-center lg:w-auto w-4/5" direction="col">
            <Image
              src="https://harisenin-storage.s3.ap-southeast-1.amazonaws.com/public/assets/pages/error-page_status-500.png"
              alt="error 500"
              className="mx-auto mb-7"
              width={306}
              height={166}
            />
            <h2 className="font-semibold lg:text-2xl">Maaf, terjadi kesalahan pada server.</h2>
            <p className="lg:text-base text-sm">
              Mungkin kamu bisa coba hapus cache pada browser atau{" "}
              <a href="https://www.harisenin.com" className="text-green font-medium">
                muat ulang halaman ini.
              </a>
            </p>
          </FlexBox>
        </div>
      </FlexBox>
    </>
  )
}

// @ts-ignore
Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
