import { useRouter } from "next/router"
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://karir.harisenin.com"
    : "https://karir.harisenin.net"

export default function useOpenGraphImage() {
  const router = useRouter()

  const searchParams = router.asPath
  // Open Graph & Twitter images need a full URL including domain
  const fullImageURL = getAbsoluteURL(`api/og${searchParams}`)
  return { imageURL: fullImageURL }
}

export const getAbsoluteURL = (path: string) => {
  return baseURL + "/" + path
}

export const useFullUrl = () => {
  const router = useRouter()
  return baseURL + router.asPath
}
