import KyMethod from "./KyMethod"
import { CheckTokenBody, RefreshTokenBody } from "@interfaces/auth"

export default class AuthRepo extends KyMethod {
  protected async rawLogin({
    user_email,
    user_password,
  }: {
    user_email: string
    user_password: string
  }) {
    return this._crudPublicMethod("/auth/login", { user_email, user_password })
  }

  protected async rawGoogleLogin({
    googleId,
    userFullname,
    email,
  }: {
    googleId: string
    userFullname: string
    email: string
  }) {
    return this._crudPublicMethod("/auth/google", {
      googleId,
      userFullname,
      email,
    })
  }

  protected async rawCheckTokenReset(body: CheckTokenBody) {
    return this._crudPublicMethod("/auth/check_token", body)
  }

  protected async rawResendVerification(email: string) {
    return this._protectedCrudMethod("/user/resend-verification", {
      method: "post",
      body: {
        user_email: email,
      },
    })
  }

  async rawLogout() {
    return this._protectedCrudMethod("/auth/logout")
  }

  protected async rawRefreshToken(body: RefreshTokenBody) {
    return this._protectedCrudMethod("/auth/refresh_tokens", { body })
  }
}
