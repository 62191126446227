import React from "react"
import { FlexBox } from "../wrapper"
import clsx from "clsx"

interface SpinKitProps {
  size?: "sm" | "md" | "lg"
  color?: string
  className?: string
}

export const Spinner: React.FC<SpinKitProps> = ({ className, size = "md" }) => {
  let style = ""

  switch (size) {
    case "sm":
      style = `absolute after:content-[''] after:animate-spin after:border-[3px] after:border-[#ddd] after:w-[20px] after:h-[20px] after:rounded-[50%] after:border-t-green`
      break
    case "md":
      style = `absolute after:content-[''] after:animate-spin after:border-[3px] after:border-[#ddd] after:w-[30px] after:h-[30px] after:rounded-[50%] after:border-t-green`
      break
    case "lg":
      style = `absolute after:content-[''] after:animate-spin after:border-[6px] after:border-[#ddd] after:w-[40px] after:h-[40px] after:rounded-[50%] after:border-t-green`
      break
  }

  return <FlexBox align="center" justify="center" className={clsx(style, className)} />
}
