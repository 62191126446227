import React from "react"
import clsx from "clsx"
import { FlexBox } from "@components/wrapper"
import { Spinner } from "@components/misc"
import Image from "next/image"

interface LoadingBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string
}

export const LoadingBox: React.FC<LoadingBoxProps> = ({
  className,
  height = "h-[300px]",
  ...props
}) => {
  return (
    <FlexBox
      justify="center"
      align="center"
      className={clsx("w-full", height, className)}
      {...props}
    >
      <Spinner size="lg" />
    </FlexBox>
  )
}

interface NoDataBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  height: string
  image: string
}

export const NoDataBox: React.FC<NoDataBoxProps> = ({
  className,
  height = "h-[300px]",
  title,
  image,
  ...props
}) => {
  return (
    <FlexBox
      justify="center"
      align="center"
      direction="col"
      className={clsx("w-full", height, className)}
      {...props}
    >
      <Image src={image} alt={title} className="w-1/3" height={300} width={300} />
      <div className="mt-5 text-lg font-semibold">{title}</div>
    </FlexBox>
  )
}
