import React, {useEffect} from "react"
import {Router, useRouter} from "next/router"
import dynamic from "next/dynamic"
import nProgress from "nprogress"
import dayjs from "dayjs"
import "dayjs/locale/id"
import {ComponentProvider} from "@lib/context/ComponentContext"
import * as gtag from "@helpers/gtag"
import Error from "./_error"

import "../styles/index.scss"
import "react-multi-carousel/lib/styles.css"
import "nprogress/nprogress.css"

const FloatingWhatsApp = dynamic(() => import("@harisenin/react-floating-whatsapp"), {ssr: false})

const isProduction = process.env.NODE_ENV === "production"

const App: ({Component, pageProps}: { Component: any; pageProps: any }) => JSX.Element = ({
                                                                                            Component,
                                                                                            pageProps,
                                                                                          }) => {
  const router = useRouter()
  dayjs.locale("id")

  useEffect(() => {
    import("react-facebook-pixel")
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.init(`${process.env.FACEBOOK_PIXEL}`) // facebookPixelId
        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView()
        })
      })
  }, [router.events])

  useEffect(() => {
    function handleRouteChange(url: URL): void {
      /* invoke analytics function only for production */
      if (isProduction) gtag.pageview(url)
    }

    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  Router.events.on("routeChangeStart", () => {
    nProgress.start()
  })
  Router.events.on("routeChangeComplete", () => {
    nProgress.done()
  })
  Router.events.on("routeChangeError", () => {
    nProgress.done()
  })

  if (pageProps.error) {
    return <Error/>
  }

  return (
    <ComponentProvider>
      <FloatingWhatsApp
        phoneNumber="+6281312117711"
        accountName="Customer Services"
        allowClickAway
        notification
        allowInUrls={["/"]}
        notificationDelay={30000}
      />
      {/* <PromoPopupProvider> */}
      <Component {...pageProps} />

      {/* </PromoPopupProvider> */}
    </ComponentProvider>
  )
}

export default App
