import { externalUrl } from "@lib/functions"

export const JWT_SECRET = "7kDeBYvzbD46QaJCzWpYtMxme04fzWZDZCGOP3Jp1bhlhIzw251e4xozjpUtZi86"

export const TOKEN_COOKIES = "HSTOKENID"

export const REFRESH_TOKEN_COOKIES = "HSRTKID"

export const GTMId = process.env.GTM_ID

export const REGENCIES = [
  {
    id: 1101,
    province_id: 11,
    regency_name: "Kab. Simeulue",
  },
  {
    id: 1102,
    province_id: 11,
    regency_name: "Kab. Aceh Singkil",
  },
  {
    id: 1103,
    province_id: 11,
    regency_name: "Kab. Aceh Selatan",
  },
  {
    id: 1104,
    province_id: 11,
    regency_name: "Kab. Aceh Tenggara",
  },
  {
    id: 1105,
    province_id: 11,
    regency_name: "Kab. Aceh Timur",
  },
  {
    id: 1106,
    province_id: 11,
    regency_name: "Kab. Aceh Tengah",
  },
  {
    id: 1107,
    province_id: 11,
    regency_name: "Kab. Aceh Barat",
  },
  {
    id: 1108,
    province_id: 11,
    regency_name: "Kab. Aceh Besar",
  },
  {
    id: 1109,
    province_id: 11,
    regency_name: "Kab. Pidie",
  },
  {
    id: 1110,
    province_id: 11,
    regency_name: "Kab. Bireuen",
  },
  {
    id: 1111,
    province_id: 11,
    regency_name: "Kab. Aceh Utara",
  },
  {
    id: 1112,
    province_id: 11,
    regency_name: "Kab. Aceh Barat Daya",
  },
  {
    id: 1113,
    province_id: 11,
    regency_name: "Kab. Gayo Lues",
  },
  {
    id: 1114,
    province_id: 11,
    regency_name: "Kab. Aceh Tamiang",
  },
  {
    id: 1115,
    province_id: 11,
    regency_name: "Kab. Nagan Raya",
  },
  {
    id: 1116,
    province_id: 11,
    regency_name: "Kab. Aceh Jaya",
  },
  {
    id: 1117,
    province_id: 11,
    regency_name: "Kab. Bener Meriah",
  },
  {
    id: 1118,
    province_id: 11,
    regency_name: "Kab. Pidie Jaya",
  },
  {
    id: 1171,
    province_id: 11,
    regency_name: "Kota Banda Aceh",
  },
  {
    id: 1172,
    province_id: 11,
    regency_name: "Kota Sabang",
  },
  {
    id: 1173,
    province_id: 11,
    regency_name: "Kota Langsa",
  },
  {
    id: 1174,
    province_id: 11,
    regency_name: "Kota Lhokseumawe",
  },
  {
    id: 1175,
    province_id: 11,
    regency_name: "Kota Subulussalam",
  },
  {
    id: 1201,
    province_id: 12,
    regency_name: "Kab. Nias",
  },
  {
    id: 1202,
    province_id: 12,
    regency_name: "Kab. Mandailing Natal",
  },
  {
    id: 1203,
    province_id: 12,
    regency_name: "Kab. Tapanuli Selatan",
  },
  {
    id: 1204,
    province_id: 12,
    regency_name: "Kab. Tapanuli Tengah",
  },
  {
    id: 1205,
    province_id: 12,
    regency_name: "Kab. Tapanuli Utara",
  },
  {
    id: 1206,
    province_id: 12,
    regency_name: "Kab. Toba Samosir",
  },
  {
    id: 1207,
    province_id: 12,
    regency_name: "Kab. Labuhan Batu",
  },
  {
    id: 1208,
    province_id: 12,
    regency_name: "Kab. Asahan",
  },
  {
    id: 1209,
    province_id: 12,
    regency_name: "Kab. Simalungun",
  },
  {
    id: 1210,
    province_id: 12,
    regency_name: "Kab. Dairi",
  },
  {
    id: 1211,
    province_id: 12,
    regency_name: "Kab. Karo",
  },
  {
    id: 1212,
    province_id: 12,
    regency_name: "Kab. Deli Serdang",
  },
  {
    id: 1213,
    province_id: 12,
    regency_name: "Kab. Langkat",
  },
  {
    id: 1214,
    province_id: 12,
    regency_name: "Kab. Nias Selatan",
  },
  {
    id: 1215,
    province_id: 12,
    regency_name: "Kab. Humbang Hasundutan",
  },
  {
    id: 1216,
    province_id: 12,
    regency_name: "Kab. Pakpak Bharat",
  },
  {
    id: 1217,
    province_id: 12,
    regency_name: "Kab. Samosir",
  },
  {
    id: 1218,
    province_id: 12,
    regency_name: "Kab. Serdang Bedagai",
  },
  {
    id: 1219,
    province_id: 12,
    regency_name: "Kab. Batu Bara",
  },
  {
    id: 1220,
    province_id: 12,
    regency_name: "Kab. Padang Lawas Utara",
  },
  {
    id: 1221,
    province_id: 12,
    regency_name: "Kab. Padang Lawas",
  },
  {
    id: 1222,
    province_id: 12,
    regency_name: "Kab. Labuhan Batu Selatan",
  },
  {
    id: 1223,
    province_id: 12,
    regency_name: "Kab. Labuhan Batu Utara",
  },
  {
    id: 1224,
    province_id: 12,
    regency_name: "Kab. Nias Utara",
  },
  {
    id: 1225,
    province_id: 12,
    regency_name: "Kab. Nias Barat",
  },
  {
    id: 1271,
    province_id: 12,
    regency_name: "Kota Sibolga",
  },
  {
    id: 1272,
    province_id: 12,
    regency_name: "Kota Tanjung Balai",
  },
  {
    id: 1273,
    province_id: 12,
    regency_name: "Kota Pematang Siantar",
  },
  {
    id: 1274,
    province_id: 12,
    regency_name: "Kota Tebing Tinggi",
  },
  {
    id: 1275,
    province_id: 12,
    regency_name: "Kota Medan",
  },
  {
    id: 1276,
    province_id: 12,
    regency_name: "Kota Binjai",
  },
  {
    id: 1277,
    province_id: 12,
    regency_name: "Kota Padangsidimpuan",
  },
  {
    id: 1278,
    province_id: 12,
    regency_name: "Kota Gunungsitoli",
  },
  {
    id: 1301,
    province_id: 13,
    regency_name: "Kab. Kepulauan Mentawai",
  },
  {
    id: 1302,
    province_id: 13,
    regency_name: "Kab. Pesisir Selatan",
  },
  {
    id: 1303,
    province_id: 13,
    regency_name: "Kab. Solok",
  },
  {
    id: 1304,
    province_id: 13,
    regency_name: "Kab. Sijunjung",
  },
  {
    id: 1305,
    province_id: 13,
    regency_name: "Kab. Tanah Datar",
  },
  {
    id: 1306,
    province_id: 13,
    regency_name: "Kab. Padang Pariaman",
  },
  {
    id: 1307,
    province_id: 13,
    regency_name: "Kab. Agam",
  },
  {
    id: 1308,
    province_id: 13,
    regency_name: "Kab. Lima Puluh Kota",
  },
  {
    id: 1309,
    province_id: 13,
    regency_name: "Kab. Pasaman",
  },
  {
    id: 1310,
    province_id: 13,
    regency_name: "Kab. Solok Selatan",
  },
  {
    id: 1311,
    province_id: 13,
    regency_name: "Kab. Dharmasraya",
  },
  {
    id: 1312,
    province_id: 13,
    regency_name: "Kab. Pasaman Barat",
  },
  {
    id: 1371,
    province_id: 13,
    regency_name: "Kota Padang",
  },
  {
    id: 1372,
    province_id: 13,
    regency_name: "Kota Solok",
  },
  {
    id: 1373,
    province_id: 13,
    regency_name: "Kota Sawah Lunto",
  },
  {
    id: 1374,
    province_id: 13,
    regency_name: "Kota Padang Panjang",
  },
  {
    id: 1375,
    province_id: 13,
    regency_name: "Kota Bukittinggi",
  },
  {
    id: 1376,
    province_id: 13,
    regency_name: "Kota Payakumbuh",
  },
  {
    id: 1377,
    province_id: 13,
    regency_name: "Kota Pariaman",
  },
  {
    id: 1401,
    province_id: 14,
    regency_name: "Kab. Kuantan Singingi",
  },
  {
    id: 1402,
    province_id: 14,
    regency_name: "Kab. Indragiri Hulu",
  },
  {
    id: 1403,
    province_id: 14,
    regency_name: "Kab. Indragiri Hilir",
  },
  {
    id: 1404,
    province_id: 14,
    regency_name: "Kab. Pelalawan",
  },
  {
    id: 1405,
    province_id: 14,
    regency_name: "Kab. Siak",
  },
  {
    id: 1406,
    province_id: 14,
    regency_name: "Kab. Kampar",
  },
  {
    id: 1407,
    province_id: 14,
    regency_name: "Kab. Rokan Hulu",
  },
  {
    id: 1408,
    province_id: 14,
    regency_name: "Kab. Bengkalis",
  },
  {
    id: 1409,
    province_id: 14,
    regency_name: "Kab. Rokan Hilir",
  },
  {
    id: 1410,
    province_id: 14,
    regency_name: "Kab. Kepulauan Meranti",
  },
  {
    id: 1471,
    province_id: 14,
    regency_name: "Kota Pekanbaru",
  },
  {
    id: 1473,
    province_id: 14,
    regency_name: "Kota Dumai",
  },
  {
    id: 1501,
    province_id: 15,
    regency_name: "Kab. Kerinci",
  },
  {
    id: 1502,
    province_id: 15,
    regency_name: "Kab. Merangin",
  },
  {
    id: 1503,
    province_id: 15,
    regency_name: "Kab. Sarolangun",
  },
  {
    id: 1504,
    province_id: 15,
    regency_name: "Kab. Batang Hari",
  },
  {
    id: 1505,
    province_id: 15,
    regency_name: "Kab. Muaro Jambi",
  },
  {
    id: 1506,
    province_id: 15,
    regency_name: "Kab. Tanjung Jabung Timur",
  },
  {
    id: 1507,
    province_id: 15,
    regency_name: "Kab. Tanjung Jabung Barat",
  },
  {
    id: 1508,
    province_id: 15,
    regency_name: "Kab. Tebo",
  },
  {
    id: 1509,
    province_id: 15,
    regency_name: "Kab. Bungo",
  },
  {
    id: 1571,
    province_id: 15,
    regency_name: "Kota Jambi",
  },
  {
    id: 1572,
    province_id: 15,
    regency_name: "Kota Sungai Penuh",
  },
  {
    id: 1601,
    province_id: 16,
    regency_name: "Kab. Ogan Komering Ulu",
  },
  {
    id: 1602,
    province_id: 16,
    regency_name: "Kab. Ogan Komering Ilir",
  },
  {
    id: 1603,
    province_id: 16,
    regency_name: "Kab. Muara Enim",
  },
  {
    id: 1604,
    province_id: 16,
    regency_name: "Kab. Lahat",
  },
  {
    id: 1605,
    province_id: 16,
    regency_name: "Kab. Musi Rawas",
  },
  {
    id: 1606,
    province_id: 16,
    regency_name: "Kab. Musi Banyuasin",
  },
  {
    id: 1607,
    province_id: 16,
    regency_name: "Kab. Banyu Asin",
  },
  {
    id: 1608,
    province_id: 16,
    regency_name: "Kab. Ogan Komering Ulu Selatan",
  },
  {
    id: 1609,
    province_id: 16,
    regency_name: "Kab. Ogan Komering Ulu Timur",
  },
  {
    id: 1610,
    province_id: 16,
    regency_name: "Kab. Ogan Ilir",
  },
  {
    id: 1611,
    province_id: 16,
    regency_name: "Kab. Empat Lawang",
  },
  {
    id: 1612,
    province_id: 16,
    regency_name: "Kab. Penukal Abab Lematang Ilir",
  },
  {
    id: 1613,
    province_id: 16,
    regency_name: "Kab. Musi Rawas Utara",
  },
  {
    id: 1671,
    province_id: 16,
    regency_name: "Kota Palembang",
  },
  {
    id: 1672,
    province_id: 16,
    regency_name: "Kota Prabumulih",
  },
  {
    id: 1673,
    province_id: 16,
    regency_name: "Kota Pagar Alam",
  },
  {
    id: 1674,
    province_id: 16,
    regency_name: "Kota Lubuklinggau",
  },
  {
    id: 1701,
    province_id: 17,
    regency_name: "Kab. Bengkulu Selatan",
  },
  {
    id: 1702,
    province_id: 17,
    regency_name: "Kab. Rejang Lebong",
  },
  {
    id: 1703,
    province_id: 17,
    regency_name: "Kab. Bengkulu Utara",
  },
  {
    id: 1704,
    province_id: 17,
    regency_name: "Kab. Kaur",
  },
  {
    id: 1705,
    province_id: 17,
    regency_name: "Kab. Seluma",
  },
  {
    id: 1706,
    province_id: 17,
    regency_name: "Kab. Mukomuko",
  },
  {
    id: 1707,
    province_id: 17,
    regency_name: "Kab. Lebong",
  },
  {
    id: 1708,
    province_id: 17,
    regency_name: "Kab. Kepahiang",
  },
  {
    id: 1709,
    province_id: 17,
    regency_name: "Kab. Bengkulu Tengah",
  },
  {
    id: 1771,
    province_id: 17,
    regency_name: "Kota Bengkulu",
  },
  {
    id: 1801,
    province_id: 18,
    regency_name: "Kab. Lampung Barat",
  },
  {
    id: 1802,
    province_id: 18,
    regency_name: "Kab. Tanggamus",
  },
  {
    id: 1803,
    province_id: 18,
    regency_name: "Kab. Lampung Selatan",
  },
  {
    id: 1804,
    province_id: 18,
    regency_name: "Kab. Lampung Timur",
  },
  {
    id: 1805,
    province_id: 18,
    regency_name: "Kab. Lampung Tengah",
  },
  {
    id: 1806,
    province_id: 18,
    regency_name: "Kab. Lampung Utara",
  },
  {
    id: 1807,
    province_id: 18,
    regency_name: "Kab. Way Kanan",
  },
  {
    id: 1808,
    province_id: 18,
    regency_name: "Kab. Tulangbawang",
  },
  {
    id: 1809,
    province_id: 18,
    regency_name: "Kab. Pesawaran",
  },
  {
    id: 1810,
    province_id: 18,
    regency_name: "Kab. Pringsewu",
  },
  {
    id: 1811,
    province_id: 18,
    regency_name: "Kab. Mesuji",
  },
  {
    id: 1812,
    province_id: 18,
    regency_name: "Kab. Tulang Bawang Barat",
  },
  {
    id: 1813,
    province_id: 18,
    regency_name: "Kab. Pesisir Barat",
  },
  {
    id: 1871,
    province_id: 18,
    regency_name: "Kota Bandar Lampung",
  },
  {
    id: 1872,
    province_id: 18,
    regency_name: "Kota Metro",
  },
  {
    id: 1901,
    province_id: 19,
    regency_name: "Kab. Bangka",
  },
  {
    id: 1902,
    province_id: 19,
    regency_name: "Kab. Belitung",
  },
  {
    id: 1903,
    province_id: 19,
    regency_name: "Kab. Bangka Barat",
  },
  {
    id: 1904,
    province_id: 19,
    regency_name: "Kab. Bangka Tengah",
  },
  {
    id: 1905,
    province_id: 19,
    regency_name: "Kab. Bangka Selatan",
  },
  {
    id: 1906,
    province_id: 19,
    regency_name: "Kab. Belitung Timur",
  },
  {
    id: 1971,
    province_id: 19,
    regency_name: "Kota Pangkal Pinang",
  },
  {
    id: 2101,
    province_id: 21,
    regency_name: "Kab. Karimun",
  },
  {
    id: 2102,
    province_id: 21,
    regency_name: "Kab. Bintan",
  },
  {
    id: 2103,
    province_id: 21,
    regency_name: "Kab. Natuna",
  },
  {
    id: 2104,
    province_id: 21,
    regency_name: "Kab. Lingga",
  },
  {
    id: 2105,
    province_id: 21,
    regency_name: "Kab. Kepulauan Anambas",
  },
  {
    id: 2171,
    province_id: 21,
    regency_name: "Kota Batam",
  },
  {
    id: 2172,
    province_id: 21,
    regency_name: "Kota Tanjung Pinang",
  },
  {
    id: 3101,
    province_id: 31,
    regency_name: "Kab. Kepulauan Seribu",
  },
  {
    id: 3171,
    province_id: 31,
    regency_name: "Kota Jakarta Selatan",
  },
  {
    id: 3172,
    province_id: 31,
    regency_name: "Kota Jakarta Timur",
  },
  {
    id: 3173,
    province_id: 31,
    regency_name: "Kota Jakarta Pusat",
  },
  {
    id: 3174,
    province_id: 31,
    regency_name: "Kota Jakarta Barat",
  },
  {
    id: 3175,
    province_id: 31,
    regency_name: "Kota Jakarta Utara",
  },
  {
    id: 3201,
    province_id: 32,
    regency_name: "Kab. Bogor",
  },
  {
    id: 3202,
    province_id: 32,
    regency_name: "Kab. Sukabumi",
  },
  {
    id: 3203,
    province_id: 32,
    regency_name: "Kab. Cianjur",
  },
  {
    id: 3204,
    province_id: 32,
    regency_name: "Kab. Bandung",
  },
  {
    id: 3205,
    province_id: 32,
    regency_name: "Kab. Garut",
  },
  {
    id: 3206,
    province_id: 32,
    regency_name: "Kab. Tasikmalaya",
  },
  {
    id: 3207,
    province_id: 32,
    regency_name: "Kab. Ciamis",
  },
  {
    id: 3208,
    province_id: 32,
    regency_name: "Kab. Kuningan",
  },
  {
    id: 3209,
    province_id: 32,
    regency_name: "Kab. Cirebon",
  },
  {
    id: 3210,
    province_id: 32,
    regency_name: "Kab. Majalengka",
  },
  {
    id: 3211,
    province_id: 32,
    regency_name: "Kab. Sumedang",
  },
  {
    id: 3212,
    province_id: 32,
    regency_name: "Kab. Indramayu",
  },
  {
    id: 3213,
    province_id: 32,
    regency_name: "Kab. Subang",
  },
  {
    id: 3214,
    province_id: 32,
    regency_name: "Kab. Purwakarta",
  },
  {
    id: 3215,
    province_id: 32,
    regency_name: "Kab. Karawang",
  },
  {
    id: 3216,
    province_id: 32,
    regency_name: "Kab. Bekasi",
  },
  {
    id: 3217,
    province_id: 32,
    regency_name: "Kab. Bandung Barat",
  },
  {
    id: 3218,
    province_id: 32,
    regency_name: "Kab. Pangandaran",
  },
  {
    id: 3271,
    province_id: 32,
    regency_name: "Kota Bogor",
  },
  {
    id: 3272,
    province_id: 32,
    regency_name: "Kota Sukabumi",
  },
  {
    id: 3273,
    province_id: 32,
    regency_name: "Kota Bandung",
  },
  {
    id: 3274,
    province_id: 32,
    regency_name: "Kota Cirebon",
  },
  {
    id: 3275,
    province_id: 32,
    regency_name: "Kota Bekasi",
  },
  {
    id: 3276,
    province_id: 32,
    regency_name: "Kota Depok",
  },
  {
    id: 3277,
    province_id: 32,
    regency_name: "Kota Cimahi",
  },
  {
    id: 3278,
    province_id: 32,
    regency_name: "Kota Tasikmalaya",
  },
  {
    id: 3279,
    province_id: 32,
    regency_name: "Kota Banjar",
  },
  {
    id: 3301,
    province_id: 33,
    regency_name: "Kab. Cilacap",
  },
  {
    id: 3302,
    province_id: 33,
    regency_name: "Kab. Banyumas",
  },
  {
    id: 3303,
    province_id: 33,
    regency_name: "Kab. Purbalingga",
  },
  {
    id: 3304,
    province_id: 33,
    regency_name: "Kab. Banjarnegara",
  },
  {
    id: 3305,
    province_id: 33,
    regency_name: "Kab. Kebumen",
  },
  {
    id: 3306,
    province_id: 33,
    regency_name: "Kab. Purworejo",
  },
  {
    id: 3307,
    province_id: 33,
    regency_name: "Kab. Wonosobo",
  },
  {
    id: 3308,
    province_id: 33,
    regency_name: "Kab. Magelang",
  },
  {
    id: 3309,
    province_id: 33,
    regency_name: "Kab. Boyolali",
  },
  {
    id: 3310,
    province_id: 33,
    regency_name: "Kab. Klaten",
  },
  {
    id: 3311,
    province_id: 33,
    regency_name: "Kab. Sukoharjo",
  },
  {
    id: 3312,
    province_id: 33,
    regency_name: "Kab. Wonogiri",
  },
  {
    id: 3313,
    province_id: 33,
    regency_name: "Kab. Karanganyar",
  },
  {
    id: 3314,
    province_id: 33,
    regency_name: "Kab. Sragen",
  },
  {
    id: 3315,
    province_id: 33,
    regency_name: "Kab. Grobogan",
  },
  {
    id: 3316,
    province_id: 33,
    regency_name: "Kab. Blora",
  },
  {
    id: 3317,
    province_id: 33,
    regency_name: "Kab. Rembang",
  },
  {
    id: 3318,
    province_id: 33,
    regency_name: "Kab. Pati",
  },
  {
    id: 3319,
    province_id: 33,
    regency_name: "Kab. Kudus",
  },
  {
    id: 3320,
    province_id: 33,
    regency_name: "Kab. Jepara",
  },
  {
    id: 3321,
    province_id: 33,
    regency_name: "Kab. Demak",
  },
  {
    id: 3322,
    province_id: 33,
    regency_name: "Kab. Semarang",
  },
  {
    id: 3323,
    province_id: 33,
    regency_name: "Kab. Temanggung",
  },
  {
    id: 3324,
    province_id: 33,
    regency_name: "Kab. Kendal",
  },
  {
    id: 3325,
    province_id: 33,
    regency_name: "Kab. Batang",
  },
  {
    id: 3326,
    province_id: 33,
    regency_name: "Kab. Pekalongan",
  },
  {
    id: 3327,
    province_id: 33,
    regency_name: "Kab. Pemalang",
  },
  {
    id: 3328,
    province_id: 33,
    regency_name: "Kab. Tegal",
  },
  {
    id: 3329,
    province_id: 33,
    regency_name: "Kab. Brebes",
  },
  {
    id: 3371,
    province_id: 33,
    regency_name: "Kota Magelang",
  },
  {
    id: 3372,
    province_id: 33,
    regency_name: "Kota Surakarta",
  },
  {
    id: 3373,
    province_id: 33,
    regency_name: "Kota Salatiga",
  },
  {
    id: 3374,
    province_id: 33,
    regency_name: "Kota Semarang",
  },
  {
    id: 3375,
    province_id: 33,
    regency_name: "Kota Pekalongan",
  },
  {
    id: 3376,
    province_id: 33,
    regency_name: "Kota Tegal",
  },
  {
    id: 3401,
    province_id: 34,
    regency_name: "Kab. Kulon Progo",
  },
  {
    id: 3402,
    province_id: 34,
    regency_name: "Kab. Bantul",
  },
  {
    id: 3403,
    province_id: 34,
    regency_name: "Kab. Gunung Kidul",
  },
  {
    id: 3404,
    province_id: 34,
    regency_name: "Kab. Sleman",
  },
  {
    id: 3471,
    province_id: 34,
    regency_name: "Kota Yogyakarta",
  },
  {
    id: 3501,
    province_id: 35,
    regency_name: "Kab. Pacitan",
  },
  {
    id: 3502,
    province_id: 35,
    regency_name: "Kab. Ponorogo",
  },
  {
    id: 3503,
    province_id: 35,
    regency_name: "Kab. Trenggalek",
  },
  {
    id: 3504,
    province_id: 35,
    regency_name: "Kab. Tulungagung",
  },
  {
    id: 3505,
    province_id: 35,
    regency_name: "Kab. Blitar",
  },
  {
    id: 3506,
    province_id: 35,
    regency_name: "Kab. Kediri",
  },
  {
    id: 3507,
    province_id: 35,
    regency_name: "Kab. Malang",
  },
  {
    id: 3508,
    province_id: 35,
    regency_name: "Kab. Lumajang",
  },
  {
    id: 3509,
    province_id: 35,
    regency_name: "Kab. Jember",
  },
  {
    id: 3510,
    province_id: 35,
    regency_name: "Kab. Banyuwangi",
  },
  {
    id: 3511,
    province_id: 35,
    regency_name: "Kab. Bondowoso",
  },
  {
    id: 3512,
    province_id: 35,
    regency_name: "Kab. Situbondo",
  },
  {
    id: 3513,
    province_id: 35,
    regency_name: "Kab. Probolinggo",
  },
  {
    id: 3514,
    province_id: 35,
    regency_name: "Kab. Pasuruan",
  },
  {
    id: 3515,
    province_id: 35,
    regency_name: "Kab. Sidoarjo",
  },
  {
    id: 3516,
    province_id: 35,
    regency_name: "Kab. Mojokerto",
  },
  {
    id: 3517,
    province_id: 35,
    regency_name: "Kab. Jombang",
  },
  {
    id: 3518,
    province_id: 35,
    regency_name: "Kab. Nganjuk",
  },
  {
    id: 3519,
    province_id: 35,
    regency_name: "Kab. Madiun",
  },
  {
    id: 3520,
    province_id: 35,
    regency_name: "Kab. Magetan",
  },
  {
    id: 3521,
    province_id: 35,
    regency_name: "Kab. Ngawi",
  },
  {
    id: 3522,
    province_id: 35,
    regency_name: "Kab. Bojonegoro",
  },
  {
    id: 3523,
    province_id: 35,
    regency_name: "Kab. Tuban",
  },
  {
    id: 3524,
    province_id: 35,
    regency_name: "Kab. Lamongan",
  },
  {
    id: 3525,
    province_id: 35,
    regency_name: "Kab. Gresik",
  },
  {
    id: 3526,
    province_id: 35,
    regency_name: "Kab. Bangkalan",
  },
  {
    id: 3527,
    province_id: 35,
    regency_name: "Kab. Sampang",
  },
  {
    id: 3528,
    province_id: 35,
    regency_name: "Kab. Pamekasan",
  },
  {
    id: 3529,
    province_id: 35,
    regency_name: "Kab. Sumenep",
  },
  {
    id: 3571,
    province_id: 35,
    regency_name: "Kota Kediri",
  },
  {
    id: 3572,
    province_id: 35,
    regency_name: "Kota Blitar",
  },
  {
    id: 3573,
    province_id: 35,
    regency_name: "Kota Malang",
  },
  {
    id: 3574,
    province_id: 35,
    regency_name: "Kota Probolinggo",
  },
  {
    id: 3575,
    province_id: 35,
    regency_name: "Kota Pasuruan",
  },
  {
    id: 3576,
    province_id: 35,
    regency_name: "Kota Mojokerto",
  },
  {
    id: 3577,
    province_id: 35,
    regency_name: "Kota Madiun",
  },
  {
    id: 3578,
    province_id: 35,
    regency_name: "Kota Surabaya",
  },
  {
    id: 3579,
    province_id: 35,
    regency_name: "Kota Batu",
  },
  {
    id: 3601,
    province_id: 36,
    regency_name: "Kab. Pandeglang",
  },
  {
    id: 3602,
    province_id: 36,
    regency_name: "Kab. Lebak",
  },
  {
    id: 3603,
    province_id: 36,
    regency_name: "Kab. Tangerang",
  },
  {
    id: 3604,
    province_id: 36,
    regency_name: "Kab. Serang",
  },
  {
    id: 3671,
    province_id: 36,
    regency_name: "Kota Tangerang",
  },
  {
    id: 3672,
    province_id: 36,
    regency_name: "Kota Cilegon",
  },
  {
    id: 3673,
    province_id: 36,
    regency_name: "Kota Serang",
  },
  {
    id: 3674,
    province_id: 36,
    regency_name: "Kota Tangerang Selatan",
  },
  {
    id: 5101,
    province_id: 51,
    regency_name: "Kab. Jembrana",
  },
  {
    id: 5102,
    province_id: 51,
    regency_name: "Kab. Tabanan",
  },
  {
    id: 5103,
    province_id: 51,
    regency_name: "Kab. Badung",
  },
  {
    id: 5104,
    province_id: 51,
    regency_name: "Kab. Gianyar",
  },
  {
    id: 5105,
    province_id: 51,
    regency_name: "Kab. Klungkung",
  },
  {
    id: 5106,
    province_id: 51,
    regency_name: "Kab. Bangli",
  },
  {
    id: 5107,
    province_id: 51,
    regency_name: "Kab. Karang Asem",
  },
  {
    id: 5108,
    province_id: 51,
    regency_name: "Kab. Buleleng",
  },
  {
    id: 5171,
    province_id: 51,
    regency_name: "Kota Denpasar",
  },
  {
    id: 5201,
    province_id: 52,
    regency_name: "Kab. Lombok Barat",
  },
  {
    id: 5202,
    province_id: 52,
    regency_name: "Kab. Lombok Tengah",
  },
  {
    id: 5203,
    province_id: 52,
    regency_name: "Kab. Lombok Timur",
  },
  {
    id: 5204,
    province_id: 52,
    regency_name: "Kab. Sumbawa",
  },
  {
    id: 5205,
    province_id: 52,
    regency_name: "Kab. Dompu",
  },
  {
    id: 5206,
    province_id: 52,
    regency_name: "Kab. Bima",
  },
  {
    id: 5207,
    province_id: 52,
    regency_name: "Kab. Sumbawa Barat",
  },
  {
    id: 5208,
    province_id: 52,
    regency_name: "Kab. Lombok Utara",
  },
  {
    id: 5271,
    province_id: 52,
    regency_name: "Kota Mataram",
  },
  {
    id: 5272,
    province_id: 52,
    regency_name: "Kota Bima",
  },
  {
    id: 5301,
    province_id: 53,
    regency_name: "Kab. Sumba Barat",
  },
  {
    id: 5302,
    province_id: 53,
    regency_name: "Kab. Sumba Timur",
  },
  {
    id: 5303,
    province_id: 53,
    regency_name: "Kab. Kupang",
  },
  {
    id: 5304,
    province_id: 53,
    regency_name: "Kab. Timor Tengah Selatan",
  },
  {
    id: 5305,
    province_id: 53,
    regency_name: "Kab. Timor Tengah Utara",
  },
  {
    id: 5306,
    province_id: 53,
    regency_name: "Kab. Belu",
  },
  {
    id: 5307,
    province_id: 53,
    regency_name: "Kab. Alor",
  },
  {
    id: 5308,
    province_id: 53,
    regency_name: "Kab. Lembata",
  },
  {
    id: 5309,
    province_id: 53,
    regency_name: "Kab. Flores Timur",
  },
  {
    id: 5310,
    province_id: 53,
    regency_name: "Kab. Sikka",
  },
  {
    id: 5311,
    province_id: 53,
    regency_name: "Kab. Ende",
  },
  {
    id: 5312,
    province_id: 53,
    regency_name: "Kab. Ngada",
  },
  {
    id: 5313,
    province_id: 53,
    regency_name: "Kab. Manggarai",
  },
  {
    id: 5314,
    province_id: 53,
    regency_name: "Kab. Rote Ndao",
  },
  {
    id: 5315,
    province_id: 53,
    regency_name: "Kab. Manggarai Barat",
  },
  {
    id: 5316,
    province_id: 53,
    regency_name: "Kab. Sumba Tengah",
  },
  {
    id: 5317,
    province_id: 53,
    regency_name: "Kab. Sumba Barat Daya",
  },
  {
    id: 5318,
    province_id: 53,
    regency_name: "Kab. Nagekeo",
  },
  {
    id: 5319,
    province_id: 53,
    regency_name: "Kab. Manggarai Timur",
  },
  {
    id: 5320,
    province_id: 53,
    regency_name: "Kab. Sabu Raijua",
  },
  {
    id: 5321,
    province_id: 53,
    regency_name: "Kab. Malaka",
  },
  {
    id: 5371,
    province_id: 53,
    regency_name: "Kota Kupang",
  },
  {
    id: 6101,
    province_id: 61,
    regency_name: "Kab. Sambas",
  },
  {
    id: 6102,
    province_id: 61,
    regency_name: "Kab. Bengkayang",
  },
  {
    id: 6103,
    province_id: 61,
    regency_name: "Kab. Landak",
  },
  {
    id: 6104,
    province_id: 61,
    regency_name: "Kab. Mempawah",
  },
  {
    id: 6105,
    province_id: 61,
    regency_name: "Kab. Sanggau",
  },
  {
    id: 6106,
    province_id: 61,
    regency_name: "Kab. Ketapang",
  },
  {
    id: 6107,
    province_id: 61,
    regency_name: "Kab. Sintang",
  },
  {
    id: 6108,
    province_id: 61,
    regency_name: "Kab. Kapuas Hulu",
  },
  {
    id: 6109,
    province_id: 61,
    regency_name: "Kab. Sekadau",
  },
  {
    id: 6110,
    province_id: 61,
    regency_name: "Kab. Melawi",
  },
  {
    id: 6111,
    province_id: 61,
    regency_name: "Kab. Kayong Utara",
  },
  {
    id: 6112,
    province_id: 61,
    regency_name: "Kab. Kubu Raya",
  },
  {
    id: 6171,
    province_id: 61,
    regency_name: "Kota Pontianak",
  },
  {
    id: 6172,
    province_id: 61,
    regency_name: "Kota Singkawang",
  },
  {
    id: 6201,
    province_id: 62,
    regency_name: "Kab. Kotawaringin Barat",
  },
  {
    id: 6202,
    province_id: 62,
    regency_name: "Kab. Kotawaringin Timur",
  },
  {
    id: 6203,
    province_id: 62,
    regency_name: "Kab. Kapuas",
  },
  {
    id: 6204,
    province_id: 62,
    regency_name: "Kab. Barito Selatan",
  },
  {
    id: 6205,
    province_id: 62,
    regency_name: "Kab. Barito Utara",
  },
  {
    id: 6206,
    province_id: 62,
    regency_name: "Kab. Sukamara",
  },
  {
    id: 6207,
    province_id: 62,
    regency_name: "Kab. Lamandau",
  },
  {
    id: 6208,
    province_id: 62,
    regency_name: "Kab. Seruyan",
  },
  {
    id: 6209,
    province_id: 62,
    regency_name: "Kab. Katingan",
  },
  {
    id: 6210,
    province_id: 62,
    regency_name: "Kab. Pulang Pisau",
  },
  {
    id: 6211,
    province_id: 62,
    regency_name: "Kab. Gunung Mas",
  },
  {
    id: 6212,
    province_id: 62,
    regency_name: "Kab. Barito Timur",
  },
  {
    id: 6213,
    province_id: 62,
    regency_name: "Kab. Murung Raya",
  },
  {
    id: 6271,
    province_id: 62,
    regency_name: "Kota Palangka Raya",
  },
  {
    id: 6301,
    province_id: 63,
    regency_name: "Kab. Tanah Laut",
  },
  {
    id: 6302,
    province_id: 63,
    regency_name: "Kab. Kota Baru",
  },
  {
    id: 6303,
    province_id: 63,
    regency_name: "Kab. Banjar",
  },
  {
    id: 6304,
    province_id: 63,
    regency_name: "Kab. Barito Kuala",
  },
  {
    id: 6305,
    province_id: 63,
    regency_name: "Kab. Tapin",
  },
  {
    id: 6306,
    province_id: 63,
    regency_name: "Kab. Hulu Sungai Selatan",
  },
  {
    id: 6307,
    province_id: 63,
    regency_name: "Kab. Hulu Sungai Tengah",
  },
  {
    id: 6308,
    province_id: 63,
    regency_name: "Kab. Hulu Sungai Utara",
  },
  {
    id: 6309,
    province_id: 63,
    regency_name: "Kab. Tabalong",
  },
  {
    id: 6310,
    province_id: 63,
    regency_name: "Kab. Tanah Bumbu",
  },
  {
    id: 6311,
    province_id: 63,
    regency_name: "Kab. Balangan",
  },
  {
    id: 6371,
    province_id: 63,
    regency_name: "Kota Banjarmasin",
  },
  {
    id: 6372,
    province_id: 63,
    regency_name: "Kota Banjar Baru",
  },
  {
    id: 6401,
    province_id: 64,
    regency_name: "Kab. Paser",
  },
  {
    id: 6402,
    province_id: 64,
    regency_name: "Kab. Kutai Barat",
  },
  {
    id: 6403,
    province_id: 64,
    regency_name: "Kab. Kutai Kartanegara",
  },
  {
    id: 6404,
    province_id: 64,
    regency_name: "Kab. Kutai Timur",
  },
  {
    id: 6405,
    province_id: 64,
    regency_name: "Kab. Berau",
  },
  {
    id: 6409,
    province_id: 64,
    regency_name: "Kab. Penajam Paser Utara",
  },
  {
    id: 6411,
    province_id: 64,
    regency_name: "Kab. Mahakam Hulu",
  },
  {
    id: 6471,
    province_id: 64,
    regency_name: "Kota Balikpapan",
  },
  {
    id: 6472,
    province_id: 64,
    regency_name: "Kota Samarinda",
  },
  {
    id: 6474,
    province_id: 64,
    regency_name: "Kota Bontang",
  },
  {
    id: 6501,
    province_id: 65,
    regency_name: "Kab. Malinau",
  },
  {
    id: 6502,
    province_id: 65,
    regency_name: "Kab. Bulungan",
  },
  {
    id: 6503,
    province_id: 65,
    regency_name: "Kab. Tana Tidung",
  },
  {
    id: 6504,
    province_id: 65,
    regency_name: "Kab. Nunukan",
  },
  {
    id: 6571,
    province_id: 65,
    regency_name: "Kota Tarakan",
  },
  {
    id: 7101,
    province_id: 71,
    regency_name: "Kab. Bolaang Mongondow",
  },
  {
    id: 7102,
    province_id: 71,
    regency_name: "Kab. Minahasa",
  },
  {
    id: 7103,
    province_id: 71,
    regency_name: "Kab. Kepulauan Sangihe",
  },
  {
    id: 7104,
    province_id: 71,
    regency_name: "Kab. Kepulauan Talaud",
  },
  {
    id: 7105,
    province_id: 71,
    regency_name: "Kab. Minahasa Selatan",
  },
  {
    id: 7106,
    province_id: 71,
    regency_name: "Kab. Minahasa Utara",
  },
  {
    id: 7107,
    province_id: 71,
    regency_name: "Kab. Bolaang Mongondow Utara",
  },
  {
    id: 7108,
    province_id: 71,
    regency_name: "Kab. Siau Tagulandang Biaro",
  },
  {
    id: 7109,
    province_id: 71,
    regency_name: "Kab. Minahasa Tenggara",
  },
  {
    id: 7110,
    province_id: 71,
    regency_name: "Kab. Bolaang Mongondow Selatan",
  },
  {
    id: 7111,
    province_id: 71,
    regency_name: "Kab. Bolaang Mongondow Timur",
  },
  {
    id: 7171,
    province_id: 71,
    regency_name: "Kota Manado",
  },
  {
    id: 7172,
    province_id: 71,
    regency_name: "Kota Bitung",
  },
  {
    id: 7173,
    province_id: 71,
    regency_name: "Kota Tomohon",
  },
  {
    id: 7174,
    province_id: 71,
    regency_name: "Kota Kotamobagu",
  },
  {
    id: 7201,
    province_id: 72,
    regency_name: "Kab. Banggai Kepulauan",
  },
  {
    id: 7202,
    province_id: 72,
    regency_name: "Kab. Banggai",
  },
  {
    id: 7203,
    province_id: 72,
    regency_name: "Kab. Morowali",
  },
  {
    id: 7204,
    province_id: 72,
    regency_name: "Kab. Poso",
  },
  {
    id: 7205,
    province_id: 72,
    regency_name: "Kab. Donggala",
  },
  {
    id: 7206,
    province_id: 72,
    regency_name: "Kab. Toli-Toli",
  },
  {
    id: 7207,
    province_id: 72,
    regency_name: "Kab. Buol",
  },
  {
    id: 7208,
    province_id: 72,
    regency_name: "Kab. Parigi Moutong",
  },
  {
    id: 7209,
    province_id: 72,
    regency_name: "Kab. Tojo Una-Una",
  },
  {
    id: 7210,
    province_id: 72,
    regency_name: "Kab. Sigi",
  },
  {
    id: 7211,
    province_id: 72,
    regency_name: "Kab. Banggai Laut",
  },
  {
    id: 7212,
    province_id: 72,
    regency_name: "Kab. Morowali Utara",
  },
  {
    id: 7271,
    province_id: 72,
    regency_name: "Kota Palu",
  },
  {
    id: 7301,
    province_id: 73,
    regency_name: "Kab. Kepulauan Selayar",
  },
  {
    id: 7302,
    province_id: 73,
    regency_name: "Kab. Bulukumba",
  },
  {
    id: 7303,
    province_id: 73,
    regency_name: "Kab. Bantaeng",
  },
  {
    id: 7304,
    province_id: 73,
    regency_name: "Kab. Jeneponto",
  },
  {
    id: 7305,
    province_id: 73,
    regency_name: "Kab. Takalar",
  },
  {
    id: 7306,
    province_id: 73,
    regency_name: "Kab. Gowa",
  },
  {
    id: 7307,
    province_id: 73,
    regency_name: "Kab. Sinjai",
  },
  {
    id: 7308,
    province_id: 73,
    regency_name: "Kab. Maros",
  },
  {
    id: 7309,
    province_id: 73,
    regency_name: "Kab. Pangkajene Dan Kepulauan",
  },
  {
    id: 7310,
    province_id: 73,
    regency_name: "Kab. Barru",
  },
  {
    id: 7311,
    province_id: 73,
    regency_name: "Kab. Bone",
  },
  {
    id: 7312,
    province_id: 73,
    regency_name: "Kab. Soppeng",
  },
  {
    id: 7313,
    province_id: 73,
    regency_name: "Kab. Wajo",
  },
  {
    id: 7314,
    province_id: 73,
    regency_name: "Kab. Sidenreng Rappang",
  },
  {
    id: 7315,
    province_id: 73,
    regency_name: "Kab. Pinrang",
  },
  {
    id: 7316,
    province_id: 73,
    regency_name: "Kab. Enrekang",
  },
  {
    id: 7317,
    province_id: 73,
    regency_name: "Kab. Luwu",
  },
  {
    id: 7318,
    province_id: 73,
    regency_name: "Kab. Tana Toraja",
  },
  {
    id: 7322,
    province_id: 73,
    regency_name: "Kab. Luwu Utara",
  },
  {
    id: 7325,
    province_id: 73,
    regency_name: "Kab. Luwu Timur",
  },
  {
    id: 7326,
    province_id: 73,
    regency_name: "Kab. Toraja Utara",
  },
  {
    id: 7371,
    province_id: 73,
    regency_name: "Kota Makassar",
  },
  {
    id: 7372,
    province_id: 73,
    regency_name: "Kota Parepare",
  },
  {
    id: 7373,
    province_id: 73,
    regency_name: "Kota Palopo",
  },
  {
    id: 7401,
    province_id: 74,
    regency_name: "Kab. Buton",
  },
  {
    id: 7402,
    province_id: 74,
    regency_name: "Kab. Muna",
  },
  {
    id: 7403,
    province_id: 74,
    regency_name: "Kab. Konawe",
  },
  {
    id: 7404,
    province_id: 74,
    regency_name: "Kab. Kolaka",
  },
  {
    id: 7405,
    province_id: 74,
    regency_name: "Kab. Konawe Selatan",
  },
  {
    id: 7406,
    province_id: 74,
    regency_name: "Kab. Bombana",
  },
  {
    id: 7407,
    province_id: 74,
    regency_name: "Kab. Wakatobi",
  },
  {
    id: 7408,
    province_id: 74,
    regency_name: "Kab. Kolaka Utara",
  },
  {
    id: 7409,
    province_id: 74,
    regency_name: "Kab. Buton Utara",
  },
  {
    id: 7410,
    province_id: 74,
    regency_name: "Kab. Konawe Utara",
  },
  {
    id: 7411,
    province_id: 74,
    regency_name: "Kab. Kolaka Timur",
  },
  {
    id: 7412,
    province_id: 74,
    regency_name: "Kab. Konawe Kepulauan",
  },
  {
    id: 7413,
    province_id: 74,
    regency_name: "Kab. Muna Barat",
  },
  {
    id: 7414,
    province_id: 74,
    regency_name: "Kab. Buton Tengah",
  },
  {
    id: 7415,
    province_id: 74,
    regency_name: "Kab. Buton Selatan",
  },
  {
    id: 7471,
    province_id: 74,
    regency_name: "Kota Kendari",
  },
  {
    id: 7472,
    province_id: 74,
    regency_name: "Kota Baubau",
  },
  {
    id: 7501,
    province_id: 75,
    regency_name: "Kab. Boalemo",
  },
  {
    id: 7502,
    province_id: 75,
    regency_name: "Kab. Gorontalo",
  },
  {
    id: 7503,
    province_id: 75,
    regency_name: "Kab. Pohuwato",
  },
  {
    id: 7504,
    province_id: 75,
    regency_name: "Kab. Bone Bolango",
  },
  {
    id: 7505,
    province_id: 75,
    regency_name: "Kab. Gorontalo Utara",
  },
  {
    id: 7571,
    province_id: 75,
    regency_name: "Kota Gorontalo",
  },
  {
    id: 7601,
    province_id: 76,
    regency_name: "Kab. Majene",
  },
  {
    id: 7602,
    province_id: 76,
    regency_name: "Kab. Polewali Mandar",
  },
  {
    id: 7603,
    province_id: 76,
    regency_name: "Kab. Mamasa",
  },
  {
    id: 7604,
    province_id: 76,
    regency_name: "Kab. Mamuju",
  },
  {
    id: 7605,
    province_id: 76,
    regency_name: "Kab. Mamuju Utara",
  },
  {
    id: 7606,
    province_id: 76,
    regency_name: "Kab. Mamuju Tengah",
  },
  {
    id: 8101,
    province_id: 81,
    regency_name: "Kab. Maluku Tenggara Barat",
  },
  {
    id: 8102,
    province_id: 81,
    regency_name: "Kab. Maluku Tenggara",
  },
  {
    id: 8103,
    province_id: 81,
    regency_name: "Kab. Maluku Tengah",
  },
  {
    id: 8104,
    province_id: 81,
    regency_name: "Kab. Buru",
  },
  {
    id: 8105,
    province_id: 81,
    regency_name: "Kab. Kepulauan Aru",
  },
  {
    id: 8106,
    province_id: 81,
    regency_name: "Kab. Seram Bagian Barat",
  },
  {
    id: 8107,
    province_id: 81,
    regency_name: "Kab. Seram Bagian Timur",
  },
  {
    id: 8108,
    province_id: 81,
    regency_name: "Kab. Maluku Barat Daya",
  },
  {
    id: 8109,
    province_id: 81,
    regency_name: "Kab. Buru Selatan",
  },
  {
    id: 8171,
    province_id: 81,
    regency_name: "Kota Ambon",
  },
  {
    id: 8172,
    province_id: 81,
    regency_name: "Kota Tual",
  },
  {
    id: 8201,
    province_id: 82,
    regency_name: "Kab. Halmahera Barat",
  },
  {
    id: 8202,
    province_id: 82,
    regency_name: "Kab. Halmahera Tengah",
  },
  {
    id: 8203,
    province_id: 82,
    regency_name: "Kab. Kepulauan Sula",
  },
  {
    id: 8204,
    province_id: 82,
    regency_name: "Kab. Halmahera Selatan",
  },
  {
    id: 8205,
    province_id: 82,
    regency_name: "Kab. Halmahera Utara",
  },
  {
    id: 8206,
    province_id: 82,
    regency_name: "Kab. Halmahera Timur",
  },
  {
    id: 8207,
    province_id: 82,
    regency_name: "Kab. Pulau Morotai",
  },
  {
    id: 8208,
    province_id: 82,
    regency_name: "Kab. Pulau Taliabu",
  },
  {
    id: 8271,
    province_id: 82,
    regency_name: "Kota Ternate",
  },
  {
    id: 8272,
    province_id: 82,
    regency_name: "Kota Tidore Kepulauan",
  },
  {
    id: 9101,
    province_id: 91,
    regency_name: "Kab. Fakfak",
  },
  {
    id: 9102,
    province_id: 91,
    regency_name: "Kab. Kaimana",
  },
  {
    id: 9103,
    province_id: 91,
    regency_name: "Kab. Teluk Wondama",
  },
  {
    id: 9104,
    province_id: 91,
    regency_name: "Kab. Teluk Bintuni",
  },
  {
    id: 9105,
    province_id: 91,
    regency_name: "Kab. Manokwari",
  },
  {
    id: 9106,
    province_id: 91,
    regency_name: "Kab. Sorong Selatan",
  },
  {
    id: 9107,
    province_id: 91,
    regency_name: "Kab. Sorong",
  },
  {
    id: 9108,
    province_id: 91,
    regency_name: "Kab. Raja Ampat",
  },
  {
    id: 9109,
    province_id: 91,
    regency_name: "Kab. Tambrauw",
  },
  {
    id: 9110,
    province_id: 91,
    regency_name: "Kab. Maybrat",
  },
  {
    id: 9111,
    province_id: 91,
    regency_name: "Kab. Manokwari Selatan",
  },
  {
    id: 9112,
    province_id: 91,
    regency_name: "Kab. Pegunungan Arfak",
  },
  {
    id: 9171,
    province_id: 91,
    regency_name: "Kota Sorong",
  },
  {
    id: 9401,
    province_id: 94,
    regency_name: "Kab. Merauke",
  },
  {
    id: 9402,
    province_id: 94,
    regency_name: "Kab. Jayawijaya",
  },
  {
    id: 9403,
    province_id: 94,
    regency_name: "Kab. Jayapura",
  },
  {
    id: 9404,
    province_id: 94,
    regency_name: "Kab. Nabire",
  },
  {
    id: 9408,
    province_id: 94,
    regency_name: "Kab. Kepulauan Yapen",
  },
  {
    id: 9409,
    province_id: 94,
    regency_name: "Kab. Biak Numfor",
  },
  {
    id: 9410,
    province_id: 94,
    regency_name: "Kab. Paniai",
  },
  {
    id: 9411,
    province_id: 94,
    regency_name: "Kab. Puncak Jaya",
  },
  {
    id: 9412,
    province_id: 94,
    regency_name: "Kab. Mimika",
  },
  {
    id: 9413,
    province_id: 94,
    regency_name: "Kab. Boven Digoel",
  },
  {
    id: 9414,
    province_id: 94,
    regency_name: "Kab. Mappi",
  },
  {
    id: 9415,
    province_id: 94,
    regency_name: "Kab. Asmat",
  },
  {
    id: 9416,
    province_id: 94,
    regency_name: "Kab. Yahukimo",
  },
  {
    id: 9417,
    province_id: 94,
    regency_name: "Kab. Pegunungan Bintang",
  },
  {
    id: 9418,
    province_id: 94,
    regency_name: "Kab. Tolikara",
  },
  {
    id: 9419,
    province_id: 94,
    regency_name: "Kab. Sarmi",
  },
  {
    id: 9420,
    province_id: 94,
    regency_name: "Kab. Keerom",
  },
  {
    id: 9426,
    province_id: 94,
    regency_name: "Kab. Waropen",
  },
  {
    id: 9427,
    province_id: 94,
    regency_name: "Kab. Supiori",
  },
  {
    id: 9428,
    province_id: 94,
    regency_name: "Kab. Mamberamo Raya",
  },
  {
    id: 9429,
    province_id: 94,
    regency_name: "Kab. Nduga",
  },
  {
    id: 9430,
    province_id: 94,
    regency_name: "Kab. Lanny Jaya",
  },
  {
    id: 9431,
    province_id: 94,
    regency_name: "Kab. Mamberamo Tengah",
  },
  {
    id: 9432,
    province_id: 94,
    regency_name: "Kab. Yalimo",
  },
  {
    id: 9433,
    province_id: 94,
    regency_name: "Kab. Puncak",
  },
  {
    id: 9434,
    province_id: 94,
    regency_name: "Kab. Dogiyai",
  },
  {
    id: 9435,
    province_id: 94,
    regency_name: "Kab. Intan Jaya",
  },
  {
    id: 9436,
    province_id: 94,
    regency_name: "Kab. Deiyai",
  },
  {
    id: 9471,
    province_id: 94,
    regency_name: "Kota Jayapura",
  },
]
