import React from "react"
import clsx from "clsx"

export const Container: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  const isSetMaxWidth = className?.includes("max-w-") ?? false
  return (
    <div
      className={clsx(
        "container mx-auto lg:w-[90%] px-4 w-full",
        className,
        !isSetMaxWidth && "max-w-screen-2xl"
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const container = "container mx-auto lg:w-[90%] px-4 w-full"
